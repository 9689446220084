import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import FormModel from '@/models/Objects/FormModel';
import { getValueFromBody } from '@/utilities/mutate';

@Component
export default class Breadcrumb extends Vue {
    private currentFormName: string = "";
    private previousForms: FormModel[] = [];

    protected created() {
        EventBus.$on('renderNewMessage', (display: boolean) => this.updateBreadcrumb());
        this.updateBreadcrumb();
    }

    protected updateBreadcrumb() {
        var formHistory: FormModel[] = this.$store.state.formHistory;
        this.currentFormName = formHistory[formHistory.length-1]?.name ?? "";
        var firstThis = formHistory.findIndex(form => form.name = this.currentFormName);
        formHistory = formHistory.filter(form => form.sessionIdentifier === this.$store.state.sessionIdentifier);// && this.previousForms.indexOf(form) <= firstThis);
        var unique: FormModel[] = [];
        for (var i = 0; i < formHistory.length; i++) {
            var existingIndex = unique.findIndex(form => form.name === formHistory[i].name);
            if (existingIndex >= 0) {
                unique.splice(existingIndex, 1);
            }
            unique.push(formHistory[i]);
        }
        this.previousForms = unique;
    } 

    private editForm(form: FormModel) {
        var formData: LooseObject = {};
        form.formFields.forEach(formField => formData[formField.name] = getValueFromBody(form.body, formField.applicationField, 0));
        EventBus.$emit('editPreviousFormData', form.name, formData, 'edit');
    }
}
